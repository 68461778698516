import axios from "axios";

export const RedeemCoupon = async (id, coupon_code) => {
        try {
        if (!id || !coupon_code) {
            throw new Error("Values are invalid");
        }
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/user/almond/voucher`, { id, coupon_code }, {
            headers: {
                "x-access-token": localStorage.getItem("token")
            }
        })
        return response;
    } catch (err) {
        return err.message;
    }
}

// import axios from "axios";

// export const RedeemCoupon = async (id, coupon_code) => {
    // try {
    //     if (!id || !coupon_code) {
    //         throw new Error("Values are invalid");
    //     }
    //     const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/user/qwickcilver/couponCreate`, { id, coupon_code }, {
    //         headers: {
    //             "x-access-token": localStorage.getItem("token")
    //         }
    //     })
    //     return response;
//     } catch (err) {
//         return err.message;
//     }
// }